import apiConfig from "./api"
import ProjectType from "../types/ProjectType"
import { IconName } from "../images/icons new/IconMapping";




export function formatDateFromEpoch(epochTimestampStr: string) {
    const epochTimestamp = parseInt(epochTimestampStr, 10);
    if (isNaN(epochTimestamp)) {
        return 'Invalid timestamp';
    }

    const date = new Date(epochTimestamp * 1000); // Multipliez par 1000 pour obtenir les millisecondes
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2,'0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}/${month}/${day}`;
}


export const fetchThirdParties = (variable :string) => {
    const queryString = `sortfield: 't.rowid'&sortorder: 'ASC'&limit: 100&sqlfilters=t.code_client:=:'${variable}'`;
    const url = `${apiConfig.baseUrl}/thirdparties?`+ queryString;
    return fetch(url, {
        method: 'GET',
        headers: { 'DOLAPIKEY': apiConfig.apiKey, 'Content-Type': 'application/json', 'Accept': 'application/json' }
    })
    .then(response => response.json())
    .then(json => {
        let idClient=null
        if (json[0].code_client === variable) {
            idClient=json[0].id;
        }
        return idClient
    });
};

export const fetchProjects = (idClient: String) => {
    const iconsList1: IconName[] = ["document", "building", "shopping", "wrench", "consuel", "enedis", "edf"];
    const iconsList2: IconName[]= ["document", "building", "enedis", "shopping", "wrench", "consuel", "edf"];
    const iconsList3: IconName[] = ["document", "shopping", "wrench"];
    
    const queryString = `sortfield: 't.rowid'&sortorder: 'ASC'&limit: 100&&sqlfilters=t.fk_soc:=:'${idClient}'`;
    const url = `${apiConfig.baseUrl}/projects?` + queryString;
    return fetch(url, {
        method: 'GET',
        headers: { 'DOLAPIKEY': apiConfig.apiKey, 'Accept': 'application/json' },
    })
    .then(response => response.json())
    .then(json => {
        const projectsClient: ProjectType.Project[] = [];
        for (let i = 0; i < json.length; i++) {
            if (json[i].socid === idClient) {
                // Create the object with the Project type
        let p: Omit<ProjectType.Project, 'icons'> = {
            socid: json[i].socid,
            id: json[i].id,
            title: json[i].title,
            tasks: [],
            creationDate: json[i].date_c,
            projectype: Number(json[i].array_options.options_projecttype)
        };

        // Determine the 'icons' based on the project type and add it to the object
        let icons: IconName[];
        switch (p.projectype) {
            case 1:
                icons = iconsList1;
                break;
            case 2:
                icons = iconsList2;
                break;
            case 3:
                icons = iconsList3;
                break;
            default:
                icons = []; // Handle unexpected project types, if necessary
                break;
            }
            // Add icons to the project object
            const projectWithIcons: ProjectType.Project = { ...p, icons };
            // Add the object to the projectsClient array
            projectsClient.push(projectWithIcons);

            // Log the object (optional)
            console.log(projectWithIcons);
            }
        }
        return projectsClient;
    });            
};

export const fetchTasks = (project:ProjectType.Project) => {
    const queryString = `sortfield: 't.rowid'&sortorder: 'ASC'&limit: 100&sqlfilters=t.fk_projet:=:'${project.id}'`;
    const url = `${apiConfig.baseUrl}/tasks?` + queryString;
    return fetch(url, {
        method: 'GET',
        headers: {
            'DOLAPIKEY': apiConfig.apiKey,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    })
    .then((response) => response.json())
    .then((json) => {
        const tasks = [];
        for (let i = 0; i < json.length; i++) {
            if (json[i].fk_project === project.id && json[i].fk_task_parent === "0" && !json[i].label.includes("facture")) {
                const t = { label: json[i].label, progress: json[i].progress, echeance: json[i].date_end, commentaire: json[i].description};
                tasks.push(t);
            }
        }

        if (project.creationDate >= 1691677203) {
            project.tasks = tasks;
            return project;
        } else {
            console.error("Projet trop vieux ou date invalide");
            // Retourner null ou une valeur appropriée pour indiquer que le projet doit être supprimé
            return null;
        }

        return project;
    });
};