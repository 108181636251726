import { IconName } from "../images/icons new/IconMapping";

export namespace ProjectTypes {
    export type Task = {
      label: string;
      progress: number;
      echeance: string;
      commentaire:string;
    }
  
    export type Project = {
      socid: string;
      id: string;
      title: string;
      tasks: Task[];
      creationDate: number;
      projectype:number;
      icons: IconName[];
    }
  }


export const defaultProject: ProjectTypes.Project = {
  socid: '',
  id: '',
  title: '',
  tasks: [],
  creationDate: 0,
  projectype: 0,
  icons: []
};

  export default ProjectTypes;