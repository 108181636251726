import { BrowserRouter } from 'react-router-dom';
import Views from "./Views"

function App() {
  return (
    <BrowserRouter basename=''>
      <Views/>
    </BrowserRouter>
  );
}

export default App;
