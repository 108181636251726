import ProjectType from "../types/ProjectType"


export type imgProp = {
    currentProject: ProjectType.Project;
    index: number;
    icon_b: string;
    icon_y: string;
    color : string []
}

function UniversalImg(props: imgProp) {
    return(
        <img src={
            props.currentProject ===undefined ? 
            props.icon_b
            : props.currentProject.tasks[props.index].progress == null?
            props.icon_b 
            : props.currentProject.tasks[props.index].progress == 100 ?
            props.icon_y 
            : props.icon_b} 
            alt="logo" className='flex items-center ml-2 w-3/5 h-3/5' />
    )
}

export default UniversalImg;