import React from "react"
import {imgProp} from "./UniversalImg"

import UniversalImg from "./UniversalImg";
import {slideFromLeftAnimation} from "../Transitions/SlideFromLeft"
import { slideFromTopAnimation } from "../Transitions/SlideFromTop";

function UniversalCircleWeb (props: imgProp){

    const animationDelay = props.index * 0.07 + "s"; // Adjust the delay as needed


    return (
        <div className="relative">
            
            <div
                className={`flex items-center justify-center border-solid bg-[#FFFFFF] rounded-full border-[5px]  h-40 w-40  hover:bg-[#D6D3D3] transition-all`}
                style={{ ...slideFromLeftAnimation, borderColor: props.color[props.index], opacity: 0, animationDelay: animationDelay }}
            >
            <div/>
                <UniversalImg currentProject={props.currentProject} index={props.index} icon_b={props.icon_b} icon_y={props.icon_y} color={props.color} />
            </div>
        </div>
    );
}

export default UniversalCircleWeb;