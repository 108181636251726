import { MapPinIcon, PhoneIcon } from '@heroicons/react/24/solid';
import logoSide from "../images/sebalyoLogoSide.png";
import { opacityFadeAnimation } from '../Transitions/OpacityFade';

function FooterMobile () {

    const email = "contact@sebalyosolar.com";
    const phoneNumber = "07 67 14 76 74";

    return (
        <div className="flex flex-grow-1 h-16 w-full bg-[#e0e0e0] mt-auto items-center justify-evenly transition-all" style={{...opacityFadeAnimation}}>
            <img src={logoSide} alt="logo" className='pl-4 h-[80%] scale-100 object-contain' />

            <a className="flex flex-row font-bold text-[#1D5D9B] items-center scale-100"href={`tel:${phoneNumber}`}>
                <PhoneIcon className="h-10 w-10 pr-4 text-[#1D5D9B]"></PhoneIcon>
                {phoneNumber}
            </a>
        </div>
    )
}
export default FooterMobile;