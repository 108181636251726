import BarLoarder from 'react-spinners/BarLoader'
import logo from '../images/sebalyoLogo.png';
import { opacityFadeAnimation } from '../Transitions/OpacityFade';

function LoadingPage() {
  
  return (
    <div className='flex flex-col h-screen items-center justify-center' style = {{ ...opacityFadeAnimation}} >
        <img src={logo} alt="logo" className='object-contain h-80' />
        <BarLoarder color="#F4D160" height={4} width={300}/> 
    </div>
  );
}

export default LoadingPage;