import ProjectType from "../types/ProjectType"


type textProp ={
    currentProject : ProjectType.Project;
    index: number;
    color : string []
    text: string;
}

function UniversalText (props: textProp){
    const animationDelay = props.index * 0.07 + "s"; // Adjust the delay as needed

    const slideFromLeftAnimation = {
        animationName: 'slideFromLeft',
        animationDuration: '2.2s', // Adjust the duration as needed
        animationTimingFunction: 'ease',
        animationDelay: animationDelay,
        animationFillMode: 'forwards' // Ensure the animation retains its final state
    };

    return (
        <div className={`flex font-face-gm justify-center text-base transition-all`} style={{...slideFromLeftAnimation, color: props.color[props.index], opacity:0, animationDelay: animationDelay}}>
            {props.text}
        </div>
    )
}

export default UniversalText;