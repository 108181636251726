// IconMapping.js (or at the top of your current file)
import building_blue from "./building_blue.png";
import building_ye from "./building_ye.png";

import document_blue from "./document_check_blue.png";
import document_ye from "./document_check_ye.png";

import wrench_blue from "./wrench_blue.png";
import wrench_ye from "./wrench_ye.png";

import consuel_blue from "./consuel_blue.png";
import consuel_ye from "./consuel_ye.png";

import edf_blue from "./edf_blue.png";
import edf_ye from "./edf_ye.png";

import enedis_blue from "./enedis_blue.png";
import enedis_ye from "./enedis_ye.png";

import shopping_blue from "./shopping_blue.png";
import shopping_ye from "./shopping_ye.png";

// Define all possible icon names
export type IconName = 'building' | 'document' | 'wrench' | 'consuel' | 'edf' | 'enedis' | 'shopping';

//Define all texts for description
export const descriptionTexts: { [key in IconName]: string[] } = {
    document: ['Dossier', 'Complet'],
    building: ['Démarche', 'Urbanisme'],
    wrench: ['Installation', 'en cours'],
    shopping: ['Commande', 'matériel'],
    consuel: ['Démarche', 'Consuel'],
    edf: ['Démarche', 'EDF'],
    enedis: ['Démarche', 'Enedis']
};

// Define the icon mapping
const iconMapping: Record<IconName, { blue: string; yellow: string }> = {
    building: { blue: building_blue, yellow: building_ye },
    document: { blue: document_blue, yellow: document_ye },
    wrench: { blue: wrench_blue, yellow: wrench_ye },
    consuel: { blue: consuel_blue, yellow: consuel_ye },
    edf: { blue: edf_blue, yellow: edf_ye },
    enedis: { blue: enedis_blue, yellow: enedis_ye },
    shopping: { blue: shopping_blue, yellow: shopping_ye }
};

export default iconMapping;
