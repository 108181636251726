import { MapPinIcon, PhoneIcon } from '@heroicons/react/24/solid';
import logoSide from "../images/sebalyoLogoSide.png";
import { opacityFadeAnimation } from '../Transitions/OpacityFade';

function FooterWeb () {

    const email = "contact@sebalyosolar.com";
    const phoneNumber = "07 67 14 76 74";

    return (
        <div className="flex flex-grow-1 h-24 w-full bg-[#e0e0e0] mt-auto items-center justify-evenly transition-all" style={{...opacityFadeAnimation}}>
        <img src={logoSide} alt="logo" className='ml-4 h-[80%] object-contain' />

        <a className="flex flex-row font-bold text-[#1D5D9B] items-center"href={`tel:${phoneNumber}`}>
        <PhoneIcon className="h-10 w-10 mr-4 text-[#1D5D9B]"></PhoneIcon>
        {phoneNumber}
        </a>

        <a className ="flex font-bold text-[#1D5D9B]" href={`mailto:${email}`}>{email}
        </a>

        <div className="flex font-bold text-[#1D5D9B]">
        <a href="https://goo.gl/maps/W3TQkNGcPo4Hx3DZ8">
            <MapPinIcon className="h-10 w-10 text-[#1D5D9B]"> </MapPinIcon>
        </a>
        Lieu dit Beauvezet <br></br> 04510 Mirabeau
        </div>
        </div>
    )
}
export default FooterWeb;