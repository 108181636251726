import ProjectType from "../types/ProjectType"

type separatorProp ={
    currentProject : ProjectType.Project;
    index: number;
    color : string []
}

function UniversalSeparator (props: separatorProp) {
    const animationDelay = props.index * 0.07 + "s"; // Adjust the delay as needed

    const slideFromLeftAnimation = {
        animationName: 'slideFromLeft',
        animationDuration: '2.2s', // Adjust the duration as needed
        animationTimingFunction: 'ease',
        animationDelay: animationDelay,
        animationFillMode: 'forwards' // Ensure the animation retains its final state
    };

    return(
        <div className={`flex flex-grow min-w-[40px] h-1 mb-10 transition-all`} style={{ ...slideFromLeftAnimation, backgroundColor: props.color[props.index], opacity:0, animationDelay: animationDelay}} />
    )
}

export default UniversalSeparator;