import { useEffect } from "react";
import { useNavigate } from "react-router";

function NotFoundPage(){
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout (() => {navigate("/")
    }, 2000)}, 
    [navigate]);

    return <div className="font-bold text-6xl"> 404 Not found</div>
}

export default NotFoundPage;