import { useState, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import React from 'react';

import UniversalSeparator from "./UniversalSeparator"
import UniversalCircle from './UniversalCircleWeb';
import UniversalText from './UniversalText';
import WebFooter from './FooterWeb';
import LoadingPage from './LoadingPage';

import { slideFromTopAnimation } from '../Transitions/SlideFromTop';
import { opacityFadeAnimation } from '../Transitions/OpacityFade';

import {formatDateFromEpoch } from '../API/pageClientData';
import ProjectType from "../types/ProjectType"
import * as datautil from '../API/pageClientData'; // Assurez-vous de spécifier le chemin correct vers le fichier datautil

import iconMapping from './../images/icons new/IconMapping' // Adjust the path accordingly
import { descriptionTexts } from './../images/icons new/IconMapping';
import { defaultProject } from '../types/ProjectType';

function decodeHtml(html:string) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }


function PageClientWEB(){

    let list_date_task: string[] = []
    const [projects, setProjects] = useState<ProjectType.Project[]>([]);
    const [selectedProject, setSelectedProject] = useState<ProjectType.Project>(defaultProject);
    const [errorState, setErrorState] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [clickedIndex, setClickedIndex] = useState<number | null>(null);

 
    const { variable } = useParams();

    const iconNames = selectedProject.icons; // Assuming icons is an array of icon names

    useEffect(() => {

        if (variable){
            datautil.fetchThirdParties(variable)
                .then(idClient => {
                    return datautil.fetchProjects(idClient);
                })
                .then(projectsClient => {
                    // Itérer sur chaque projet et appeler fetchTasks pour chaque projet
                    const fetchTaskPromises = projectsClient.map(project => {
                        return datautil.fetchTasks(project);
                    });
        
                    return Promise.all(fetchTaskPromises);
                })
                .then(updatedProjects => {
                    if (updatedProjects.every(project => project !== null)) {
                        // Mettre à jour l'état des projets ici
                        setProjects(updatedProjects as ProjectType.Project[]);
                    } else {
                        // Gérer le cas où certaines promesses ont échoué
                        setErrorState(true);
                    }

                    setTimeout(() => {
                        setIsLoading(false); // Mettre à jour l'état après un délai
                      }, 500); // Délai 

                })
                .catch(error => {
                    console.error(error);
                    setErrorState(true);
                });
            }
        }, []);

    if (selectedProject != null){
        for (let i = 0; i < selectedProject.tasks.length; i++){
            if (selectedProject.tasks[i].progress != 100){
                list_date_task.push(formatDateFromEpoch(selectedProject.tasks[i].echeance))
            }
        }
    }
    list_date_task.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    let color : string[] = []
    
    if(selectedProject != undefined){
        for (let i = 0; i < selectedProject.tasks.length; i++){
            if (selectedProject.tasks[i].progress != null && selectedProject.tasks[i].progress == 100){
                color[i]="#F4D160"
            }
            else{
                color[i]='#1D5D9B'
            }
        }
    }
    else {
        for (let i = 0; i < 8; i++){
        color[i]='#1D5D9B';
        }
    }

    if (isLoading) {
        return <LoadingPage />;
      } else {

    return(
        //couleur pour le fond : FBEEAC
        //couleur pour les cases : F4D160
        //Largeur min 1100

    <div className="flex flex-col bg-[#EEEEEE] min-h-screen">

        {errorState && <Navigate to ={`/fetchError`}/>}

        {/* Title */}
        <div className="flex flex-col items-center transition-all" style={{...slideFromTopAnimation}}>
            <div className="flex text-[#1D5D9B] text-6xl mt-8 font-face-gm justify-center items-center flex-grow-1">
                Suivis de vos projets 
            </div>
            <div className="flex items-center bg-[#1D5D9B] h-2 w-80 mt-[1%]">
            </div>
        </div>

        {/* Slider */}
        <div className="flex flex-col items-center transition-all" style={{ ...opacityFadeAnimation}}>
            <div className={`bg-[#e0e0e0] flex-grow-0 mt-20 rounded shadow-2xl mx-auto px-3`} style={{ maxWidth: 'calc(275px * 3 + 20px)', overflowX: 'auto', paddingTop: '5px', paddingBottom: '5px' }}>
                <div className="flex" style={{ minWidth: `${Math.max(275, 275 * Math.min(3, projects.length))}px`, width: 'fit-content', minHeight: '80px', ...opacityFadeAnimation}}>
                    {projects.map((project, index) => (
                    <button
                    key={project.id}
                    className={`flex text-[#FFFFFF] text-2xl font-face-gm bg-[#F4D160] h-[80px] min-w-[250px] items-center justify-center rounded-lg mx-2 my-1 hover:bg-[#c29503] active:bg-[#F4D160]`}
                    onClick={() => setSelectedProject(project)}
                    >
                    {project.title}
                    </button>
                ))}
                </div>
            </div>
        </div>
        

        {/* Avancement */}
        <div className='flex flex-row justify-center items-center mt-[7%] ml-[15%] mr-[15%] mb-[5%]'>
            {iconNames.map((iconName, index) => (
                <React.Fragment key={iconName}>
                    <div className="flex flex-col items-center justify-center" onClick={() => setClickedIndex(index)}>
                        <UniversalCircle 
                            currentProject={selectedProject} 
                            index={index} 
                            icon_b={iconMapping[iconName]?.blue} 
                            icon_y={iconMapping[iconName]?.yellow} 
                            color={color} 
                            
                        />
                        {descriptionTexts[iconName]?.map((text, textIndex) => (
                            <UniversalText 
                                key={textIndex}
                                currentProject={selectedProject} 
                                index={index} 
                                color={color} 
                                text={text} 
                            />
                        ))}
                    </div>
                    {index < iconNames.length - 1 && (
                        <UniversalSeparator 
                            currentProject={selectedProject} 
                            index={index} 
                            color={color} 
                        />
                    )}
                </React.Fragment>
            ))}
        </div>
        {clickedIndex !=null && selectedProject != undefined && (
                <div
                    className="flex flex-grow justify-evenly flex-row w-full min-h-min mb-[2%] min-w-[200px] "
                    style={{ ...opacityFadeAnimation }}
                >
                    <div className='flex min-w-[15%]'/>

                    <p className="bg-gray-200 rounded-md shadow-md text-s top-0 left-0 items-center justify-center"
                  dangerouslySetInnerHTML={{
                    __html: decodeHtml(selectedProject.tasks[clickedIndex].commentaire).replace(/\r\n/g, "<br />"),
                  }}
                />
                <div className='flex min-w-[15%]'/>
                </div>
                )}
        {/* <div className='flex flex-row justify-center items-center bg-[#EEEEEE] h-fit'></div> */}
    
    <WebFooter/>

    </div>
    )
}}

export default PageClientWEB;