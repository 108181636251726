import React, { useState } from "react";
import { imgProp } from "./UniversalImg";

import UniversalImg from "./UniversalImg";
import { slideFromLeftAnimation } from "../Transitions/SlideFromLeft";
import { slideFromTopAnimation } from "../Transitions/SlideFromTop";
import { opacityFadeAnimation } from "../Transitions/OpacityFade";

function UniversalCircleCrop(props: imgProp) {

  const [isClicked, setIsClicked] = React.useState(false);


  return (
    <div className="flex flex-col items-center justify-center">
      <div
        className={`flex items-center justify-center border-solid bg-[#FFFFFF] rounded-full border-[5px]  h-40 w-40  hover:bg-[#D6D3D3] transition-all`}
        style={{
          ...slideFromLeftAnimation,
          borderColor: props.color[props.index],
          opacity: 0,
        }}
      >
        <UniversalImg
          currentProject={props.currentProject}
          index={props.index}
          icon_b={props.icon_b}
          icon_y={props.icon_y}
          color={props.color}
        />
      </div>
    </div>
  );
}

export default UniversalCircleCrop;
