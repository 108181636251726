import { Routes, Route } from 'react-router-dom';
import LoginPage from './loginPage';
import PageClient from './pageclient'
import NotFoundPage from './notFoundPage'
import ErrorFetchPage from './ErrorFetchPage';

function Views() {
  return (
    <Routes>
        <Route index element={<LoginPage/>}/>
        <Route path="/clientPage/:variable" element={<PageClient/>}/>
        <Route path="/fetchError" element={<ErrorFetchPage/>}/>
        <Route path ="*" element={<NotFoundPage/>}/>
    </Routes>
  );
}

export default Views;
