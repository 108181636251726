import React, { useState, useEffect } from 'react';
import logo from '../images/sebalyoLogo.png';
import { Navigate } from 'react-router-dom';
import MobileFooter from './FooterCrop';
import { opacityFadeAnimation } from '../Transitions/OpacityFade';

import apiConfig from '../API/api';

import ErrorFetchPage from '../ErrorFetchPage';


const data = {
    sortfield: 't.rowid',
    sortoder: 'ASC',
    limit: 1000000,
}


function LoginPageMobile() {

  const [numeroClient, setNumeroClient] = useState('');
  const [result, setResult] = useState('');
  const [isNavigating, setIsNavigating] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const queryString = `sortfield: 't.rowid'&sortorder: 'ASC'&limit: 100&sqlfilters=t.code_client:=:'${numeroClient}'`;
  const urlThirdParties = `${apiConfig.baseUrl}/thirdparties?` + queryString;

  const handleResize = () => {
      setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);


  const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      // Fetch the data and compare the input to the code_client field
      fetch(urlThirdParties, {
        method: 'GET',
        headers: { 'DOLAPIKEY': apiConfig.apiKey, 'Content-Type': 'application/json', 'Accept': 'application/json' },
      })
        .then(response => response.json())
        .then(data => {
            if (data[0].code_client == numeroClient){
              setIsNavigating(true);
              setResult('');
            }
            else {
              setResult('Numéro client invalide');
              setNumeroClient('');
            }
        })
        .catch(error => {
          console.log("erreur 404")
          setResult('Numéro client invalide');
          setNumeroClient('');
        });
    };

    return (

        // main div
        <div className= "flex flex-col bg-gray-50 min-h-screen justify-center place-content-evenly">

            {/* Logo */}
            <div className="flex items-center justify-center mt-20 transition-all" style={{...opacityFadeAnimation}}>
            <img src={logo} alt="logo" className='object-contain h-80' />
            </div>

            {/* login */}
            <div className="flex flex-col items-center justify-center mt-8 transition-all mb-[10%]" style={{...opacityFadeAnimation}}>
              {result && <p className="mt-auto items-center text-sm text-red-500">
                {result}
                </p>}
              <form onSubmit={handleSubmit} className='flex flex-col items-center'>
                    <input 
                        type = "text"
                        name = "Numéro client" 
                        value={numeroClient}
                        onChange={(e) => {
                          setNumeroClient(e.target.value)
                          setResult(''); // Clear the result when input changes
                        }}
                        placeholder = "Numéro client"
                        className={`flex items-center shadow-md bg-[#FBEEAC] placeholder-[#F4D160] text-[#ddb42d] font-face-gm border-2 font-bold p-2 w-60 rounded-md focus:outline-none focus:border-[#ddb120] ${result ? 'border-red-500 border-2' : ''}`}
                        />
         {/* Use Link to navigate to PageClient if shouldNavigate is true */}
         {isNavigating && <Navigate to={`/clientPage/${numeroClient}`}/>}
         <button
           type="submit"
           className="shadow-md bg-[#1D5D9B] text-white font-face-gm pl-4 pr-4 pt-2 pb-2 rounded-full hover:bg-[#0c4781] active:bg-[#1D5D9B] mt-5"
         >
           Connexion
         </button>
            </form>
            </div>
          <MobileFooter/>
        </div>
    );
}

export default LoginPageMobile;