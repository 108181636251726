import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import React from 'react';
import PageClientCrop from './components/PageClientCrop';
import PageClientWEB from './components/PageClientWeb';
import PageClientMobile from './components/PageClientMobile';

function PageClient() {

    const isCrop = useMediaQuery({ minWidth: 800, maxWidth: 1350});
    const isMobile = useMediaQuery ({ maxWidth: 800})

    return (
        <div>
        {isCrop ? (
            <PageClientCrop/>
        ) : isMobile ? (
            <PageClientMobile/>
        ) : <PageClientWEB/>
        }
        </div>
    )
}

export default PageClient;