import { useEffect, useState } from 'react';
import React from 'react';
import LoginPageMobile from './components/LoginPageMobile';
import LoginpageWeb from './components/LoginPageWeb';

function PageClient() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Define the breakpoints for mobile and web views
    const mobileBreakpoint = 850;

    if (screenWidth <= mobileBreakpoint) {
        return <LoginPageMobile />;
    } else {
        return <LoginpageWeb />;
    }
}

export default PageClient;