import { useEffect } from "react";
import { useNavigate } from "react-router";

function ErrorFetchPage(){

    const navigate = useNavigate();
    useEffect(() => {
        setTimeout (() => {navigate("/")
    }, 2000)}, 
    [navigate]);

    return (
    <div className="flex items-center justify-center">
        <div className=" flex font-bold text-3xl"> Erreur de réponse de la base de donnée <br/> </div>
        <div className="flex font-bold text-3xl"> Veuillez revenir plus tard </div>
    </div>
    )
}
export default ErrorFetchPage;